<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'
import type { Folder } from '~/types'

interface Breadcrumb { id: string, text: string }

const props = defineProps<{
  breadcrumbs?: Array<App.Data.Folder.BreadcrumbData>
  currentFolder?: Folder
  onCrumbClicked?: (value: Breadcrumb) => any
}>()

const emit = defineEmits<{
  crumbClicked: [value: Breadcrumb]
}>()

const mappedBreadcrumbs = computed(() => {
  const crumbs: Breadcrumb[] = [...(props.breadcrumbs ?? [])]
    .sort((a, b) => a.depth < b.depth ? 1 : -1)
    .map(({ id, name }) => ({ id, text: name }))

  if (props.currentFolder) {
    crumbs.push({
      id: props.currentFolder.id,
      text: props.currentFolder.name,
    })
  }

  return crumbs
})

const hasCrumbClickedListener = computed(() => !!props.onCrumbClicked)

const wrapper = ref<HTMLDivElement>()
const fullContent = ref<HTMLDivElement>()

const wrapperSize = useElementSize(wrapper)
const fullContentSize = useElementSize(fullContent)

const fullPathHidden = computed(() => wrapperSize.width.value < fullContentSize.width.value)
</script>

<template>
  <div
    ref="wrapper"
    class="relative w-full max-w-full overflow-hidden"
  >
    <div
      ref="fullContent"
      class="absolute inset-0 flex w-max items-center gap-1"
      :class="{ invisible: fullPathHidden }"
    >
      <template
        v-for="(crumb, index) in mappedBreadcrumbs"
        :key="`crumb-${crumb.id}`"
      >
        <slot :crumb="crumb">
          <span>{{ crumb.text }}</span>
        </slot>
        <span v-if="index !== (mappedBreadcrumbs.length - 1)"> / </span>
      </template>
    </div>

    <div
      class="relative flex w-full gap-0.5"
      :class="{ invisible: !fullPathHidden }"
    >
      <template
        v-for="(crumb, index) in mappedBreadcrumbs"
        :key="`crumb-short-${crumb.id}`"
      >
        <span
          v-if="![
            (mappedBreadcrumbs.length - 1),
            currentFolder && (mappedBreadcrumbs.length - 2),
          ].includes(index)"
          :title="crumb.text"
          v-bind="hasCrumbClickedListener
            ? { role: 'button', tabindex: 0, onClick: () => emit('crumbClicked', crumb) }
            : undefined"
        >…</span>
        <slot
          v-else
          :crumb="crumb"
        >
          <span
            v-if="currentFolder && index === (mappedBreadcrumbs.length - 2)"
            class="line-clamp-1 w-auto max-w-[20ch] shrink-0 break-all"
            :title="crumb.text"
          >{{ crumb.text }}</span>
          <span
            v-else-if="index === (mappedBreadcrumbs.length - 1)"
            class="line-clamp-1 min-w-0 break-all"
            :title="crumb.text"
          >{{ crumb.text }}</span>
        </slot>
        <span v-if="index !== (mappedBreadcrumbs.length - 1)"> / </span>
      </template>
    </div>
  </div>
</template>
